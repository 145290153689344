import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import AOS from 'aos';
import Contact from '../../components/contactBlock.vue';
export default {
  name: 'MynLink',
  components: {
    Contact: Contact
  },
  mounted: function mounted() {
    AOS.init();
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.defaults({
      toggleActions: 'restart pause resume pause'
    });
  }
};